<template>
  <main class="main">
    <BreadCrumbs :pages="pages" />
    <section class="main__auth auth">
      <div class="auth__container">
        <div v-if="!step2fa" class="auth-card">
          <div class="auth-card__header">
            <h1 class="auth-card__title">
              {{ $t('auth.login') }}
            </h1>
          </div>
          <div v-if="errors.length > 0" class="errors-area">
            <div class="h6">
              {{ $t('auth.something_wrong') }}
            </div>
            <p v-for="(message, ind) in errors" :key="ind">
              {{ message }}
            </p>
          </div>
          <div class="auth-card__form form-auth">
            <div class="form-auth__part">
              <label for="email" class="form-auth__label">
                {{ $t('auth.email') }}
              </label>
              <input id="email" v-model="form.email" type="email" class="form-auth__input">
            </div>
            <div class="form-auth__part">
              <label for="password" class="form-auth__label">
                {{ $t('auth.password') }}
              </label>
              <input id="password" v-model="form.password" type="password" class="form-auth__input">
            </div>
            <div class="form-auth__part captcha">
              <recaptcha :data-theme="theme" />
            </div>

            <button type="button" class="form-auth__button" :disabled="loading" @click="loginToAccount">
              <span v-if="!loading">
                {{ $t('auth.enter') }}
              </span>
              <span v-else>
                <i class="bx bx-loader-alt loading"></i>
              </span>
            </button>
          </div>
          <nuxt-link class="reset-link" :to="localePath('/forgot')">
            {{ $t('auth.forgot') }}
          </nuxt-link>
          <p class="auth-card__another">
            <span>
              {{ $t('auth.new_here') }}
            </span>
                          &nbsp;
            <nuxt-link :to="localePath('/register')">
              {{ $t('auth.create_account') }}
            </nuxt-link>
          </p>
        </div>
        <div v-else class="auth-card">
          <div class="auth-card__header">
            <h2 class="auth-card__title">
              {{ $t('auth.enter_2fa') }}
            </h2>
            <p class="auth-card__desc">
              {{ $t('auth.enter_2fa_desc') }}
            </p>
          </div>
          <div v-if="errors.length > 0" class="errors-area">
            <div class="h6">
              {{ $t('auth.something_wrong') }}
            </div>
            <p v-for="(message, ind) in errors" :key="ind">
              {{ message }}
            </p>
          </div>
          <div class="auth-card__form form-auth">
            <div class="form-auth__part passcode-form">
              <div
                v-for="ind in 6"
                :key="ind"
                class="m-field"
              >
                <input
                  :ref="'passcode' + ind"
                  v-model="form.gcode[ind - 1]"
                  type="number"
                  class="form-auth__input number"
                  @input="changeGcode(ind)"
                >
              </div>
            </div>

            <button type="button" class="form-auth__button" :disabled="loading" @click="enter2fa">
              <span v-if="!loading">
                {{ $t('auth.continue') }}
              </span>
              <span v-else>
                <i class="bx bx-loader-alt loading"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  hydrateWhenVisible
} from 'vue-lazy-hydration'

export default {
  name: 'LoginComponent',
  components: {
    BreadCrumbs: hydrateWhenVisible(() => import('~/components/common/BreadCrumbs'))
  },
  data() {
    return {
      loading: false,
      form: {
        gcode: [],
        email: '',
        password: '',
        recaptcha: null
      },
      step2fa: false,
      errors: [],
      pages: [
        {
          label: this.$t('common.home'),
          link: '/'
        },
        {
          label: this.$t('common.login'),
          link: '/login'
        }
      ]
    }
  },
  async fetch({ store, error, route }) {
    try {
      const pageName = route.name.split('___')
      const seoData = store.getters['seos/seosByUri'](pageName[0])
      if (seoData === null) {
        await store.dispatch('seos/fetchSeos', {
          uri: route.name
        })
      }
    } catch (err) {
      error(err)
    }
  },
  head({ $config: { appUrl } }) {
    return {
      title: this.meta ? this.meta.title : this.$t('common.launching'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.meta ? this.meta.description : ''
        },
        {
          hid: 'og-description',
          name: 'og:description',
          content: this.meta ? this.meta.description : ''
        },
        {
          hid: 'og-title',
          name: 'og:title',
          content: this.meta
            ? this.meta.title
            : this.$t('common.launching')
        },
        {
          hid: 'og-image',
          name: 'og:image',
          content: this.meta
            ? this.meta.image
            : appUrl + 'seo/default.jpg'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.meta ? this.meta.keyword : ''
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['theme']),
    ...mapGetters('auth', [
      'user'
    ]),
    ...mapGetters('seos', [
      'seosByUri'
    ]),
    meta() {
      const pageName = this.$nuxt.$route.name.split('___')
      return this.seosByUri(pageName[0])
    }
  },
  methods: {
    ...mapActions('auth', [
      'login',
      'login2fa',
      'loginFacebook',
      'loginLinkedin'
    ]),
    async loginToAccount() {
      this.loading = true
      this.errors = []
      try {
        this.form.recaptcha = await this.$recaptcha.getResponse()
        await this.validateForm()
        if (this.errors.length === 0) {
          await this.login(this.form)
            .then(() => {
              this.loading = false
              if (this.user === null) {
                this.step2fa = true
              } else {
                this.$recaptcha.reset()
                this.$router.push({
                  path: this.localePath('/account')
                })
              }
            })
            .catch((err) => {
              this.$recaptcha.reset()
              this.loading = false
              if (err.response.status === 422) {
                this.errors.push(this.$t('auth.email_or_pass_invalid'))
              }
            })
        } else {
          this.loading = false
        }
      } catch (err) {
        this.loading = false
        this.errors.push(this.$t('auth.recaptcha_invalid'))
        this.$recaptcha.reset()
      }
    },
    async enter2fa() {
      this.loading = true
      this.errors = []
      if (this.form.gcode.length !== 6) {
        this.errors.push(this.$t('auth.error_gcode'))
        return
      }
      try {
        await this.login2fa({
          ...this.form,
          gcode: this.form.gcode.join('')
        })
          .then(() => {
            this.loading = false
            this.$router.push({
              path: this.localePath('/account')
            })
            this.form.gcode = []
          })
          .catch((err) => {
            this.form.gcode = []
            this.$refs.passcode1[0].focus()
            console.log(err)
            this.loading = false
            if (err.response.status === 422) {
              this.errors.push(this.$t('auth.error_2fa'))
            }
          })
      } catch (err) {
        this.loading = false
      }
    },
    validateForm() {
      this.errors = []
      if (this.form.email.length < 6 || this.form.email.length > 155 || !this.$validateEmail(this.form.email)) {
        this.errors.push(this.$t('auth.email_invalid'))
      }
      if (this.form.password.length < 6 || this.form.password.length > 75) {
        this.errors.push(this.$t('auth.password_invalid'))
      }
      if (this.form.recaptcha.length === 0) {
        this.errors.push(this.$t('auth.recaptcha_invalid'))
      }
    },
    async authFacebook() {
      this.loading = true
      await this.loginFacebook()
        .then(() => {
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    async authLinkedin() {
      this.loading = true
      await this.loginFacebook()
        .then(() => {
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    changeGcode(index) {
      if (index !== null && typeof index === 'number' && index < 6) {
        const nextIndex = index + 1
        this.$refs['passcode' + nextIndex][0].focus()
      }
      if (this.form.gcode[index - 1].length > 1) {
        this.form.gcode[index - 1] = this.form.gcode[index - 1][1]
      }
      if (index !== null && typeof index === 'number' && index === 6) {
        this.enter2fa()
      }
    }
  }
}
</script>

<style scoped>
  @import url('~/assets/css/login.css');
</style>
