var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "main" },
    [
      _c("BreadCrumbs", { attrs: { pages: _vm.pages } }),
      _vm._v(" "),
      _c("section", { staticClass: "main__auth auth" }, [
        _c("div", { staticClass: "auth__container" }, [
          !_vm.step2fa
            ? _c(
                "div",
                { staticClass: "auth-card" },
                [
                  _c("div", { staticClass: "auth-card__header" }, [
                    _c("h1", { staticClass: "auth-card__title" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("auth.login")) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.errors.length > 0
                    ? _c(
                        "div",
                        { staticClass: "errors-area" },
                        [
                          _c("div", { staticClass: "h6" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("auth.something_wrong")) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.errors, function (message, ind) {
                            return _c("p", { key: ind }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(message) +
                                  "\n          "
                              ),
                            ])
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "auth-card__form form-auth" }, [
                    _c("div", { staticClass: "form-auth__part" }, [
                      _c(
                        "label",
                        {
                          staticClass: "form-auth__label",
                          attrs: { for: "email" },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("auth.email")) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.email,
                            expression: "form.email",
                          },
                        ],
                        staticClass: "form-auth__input",
                        attrs: { id: "email", type: "email" },
                        domProps: { value: _vm.form.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "email", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-auth__part" }, [
                      _c(
                        "label",
                        {
                          staticClass: "form-auth__label",
                          attrs: { for: "password" },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("auth.password")) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.password,
                            expression: "form.password",
                          },
                        ],
                        staticClass: "form-auth__input",
                        attrs: { id: "password", type: "password" },
                        domProps: { value: _vm.form.password },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "password", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-auth__part captcha" },
                      [_c("recaptcha", { attrs: { "data-theme": _vm.theme } })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "form-auth__button",
                        attrs: { type: "button", disabled: _vm.loading },
                        on: { click: _vm.loginToAccount },
                      },
                      [
                        !_vm.loading
                          ? _c("span", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("auth.enter")) +
                                  "\n            "
                              ),
                            ])
                          : _c("span", [
                              _c("i", {
                                staticClass: "bx bx-loader-alt loading",
                              }),
                            ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "reset-link",
                      attrs: { to: _vm.localePath("/forgot") },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("auth.forgot")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "auth-card__another" },
                    [
                      _c("span", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("auth.new_here")) +
                            "\n          "
                        ),
                      ]),
                      _vm._v("\n                         \n          "),
                      _c(
                        "nuxt-link",
                        { attrs: { to: _vm.localePath("/register") } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("auth.create_account")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("div", { staticClass: "auth-card" }, [
                _c("div", { staticClass: "auth-card__header" }, [
                  _c("h2", { staticClass: "auth-card__title" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("auth.enter_2fa")) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "auth-card__desc" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("auth.enter_2fa_desc")) +
                        "\n          "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.errors.length > 0
                  ? _c(
                      "div",
                      { staticClass: "errors-area" },
                      [
                        _c("div", { staticClass: "h6" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("auth.something_wrong")) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.errors, function (message, ind) {
                          return _c("p", { key: ind }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(message) +
                                "\n          "
                            ),
                          ])
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "auth-card__form form-auth" }, [
                  _c(
                    "div",
                    { staticClass: "form-auth__part passcode-form" },
                    _vm._l(6, function (ind) {
                      return _c("div", { key: ind, staticClass: "m-field" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.gcode[ind - 1],
                              expression: "form.gcode[ind - 1]",
                            },
                          ],
                          ref: "passcode" + ind,
                          refInFor: true,
                          staticClass: "form-auth__input number",
                          attrs: { type: "number" },
                          domProps: { value: _vm.form.gcode[ind - 1] },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.form.gcode,
                                  ind - 1,
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                return _vm.changeGcode(ind)
                              },
                            ],
                          },
                        }),
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "form-auth__button",
                      attrs: { type: "button", disabled: _vm.loading },
                      on: { click: _vm.enter2fa },
                    },
                    [
                      !_vm.loading
                        ? _c("span", [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("auth.continue")) +
                                "\n            "
                            ),
                          ])
                        : _c("span", [
                            _c("i", {
                              staticClass: "bx bx-loader-alt loading",
                            }),
                          ]),
                    ]
                  ),
                ]),
              ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }